import message from './message'
// 系统属性方法
export function isPhone () {
  // 平板竖屏认为是手机端（即展示H5）
  return (!isRow() && isWithinPhoneWidthRange())
  // return (isMobile() && !isIPadRow())
}

// 添加对手机宽度的检查（假设手机宽度通常小于800像素）
const isWithinPhoneWidthRange = function () {
  return window.screen.width < 850 // 这个值可以根据实际情况调整
}

/**
 * 是否是横屏
 */
export const isRow = function () {
  const isRow = (window.orientation && (window.orientation === 90 || window.orientation === -90))
  return isRow
}

/**
 * 是否是平板横屏（横屏window.orientation = 90或-90；竖屏window.orientation = 0或180）
 */
export const isIPadRow = function () {
  const isRow = (window.orientation && (window.orientation === 90 || window.orientation === -90))
  const isIpad = window.screen.width > 500
  return (isIpad && isRow)
}

/**
 * 判断访问来源
 * @returns {*|string}
 */
export const getBrowerType = () => {
  const url = window.location.href
  const str = url.substr(url.indexOf('?') + 1)
  const arr = str.split('&')
  const result = {}
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i].split('=')
    result[item[0]] = item[1]
  }
  return result.from || 'web'
}

/**
 * 获取url中的参数
 * @returns
 */
export const getQueryFromLocation = (url) => {
  // 如果URL没有提供，则使用当前页面的URL
  if (!url) {
    url = window.location.href
  }
  // 提取#后面的部分（如果存在）
  const hashPart = url.split('#')[1] || ''
  // 查找?后面的部分（查询参数）
  const queryParamsString = hashPart.split('?')[1] || ''
  // 创建一个空对象来存储查询参数
  const queryParams = {}
  // 如果存在查询参数，则解析它们
  if (queryParamsString) {
    queryParamsString.split('&').forEach(function (param) {
      const parts = param.split('=')
      if (parts.length === 2) {
        queryParams[parts[0]] = decodeURIComponent(parts[1])
      }
    })
  }
  return queryParams
}

/**
 * 防抖
 * @param {*} func 方法
 * @param {*} wait 时间(单位毫秒)
 * @returns
 */
export const debounce = (func, wait = 500) => {
  let timer = null
  return function (...args) {
    const context = this
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(context, args)
    }, wait)
  }
}

export const throttle = (func, wait = 500) => {
  let timer = null
  return function (...args) {
    const context = this
    if (!timer) {
      func.apply(context, args)
      timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
      }, wait)
    }
  }
}
/**
 * 复制文本
 * @param text 复制文本内容
 * @param msg  复制文本内容成功的提示语
 */
export const copyLink = (text, msg) => {
  // 检查浏览器是否支持 Clipboard API
  if (!navigator.clipboard) {
    // 如果不支持，则使用传统的 document.execCommand("copy") 方式
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.readOnly = true // 防止屏幕上下抖动
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
    message.success(msg !== undefined ? msg : '复制成功')
    return
  }

  // 使用 Clipboard API 复制内容到剪切板
  navigator.clipboard.writeText(text).then(() => {
    message.success(msg !== undefined ? msg : '复制成功')
  }).catch(() => {
    message.error('抱歉，复制失败了！')
  }).finally(() => {})
}
